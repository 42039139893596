import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { RailAppAgencyService } from './rail-app-agency.service';
import { RailAppProviderService } from './rail-app-provider.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RailappSocket } from '../websocket/railapp-socket';

@Injectable({
  providedIn: 'root',
})
export class RailAppService {
  railAPPSesionSeconds = environment.sabreRailAPPSessionSecond;
  xmlCompleteAction$ = new Subject();
  destroy$: Subject<void> = new Subject();
  channelValue = ''

  constructor(
    private http: HttpClient,
    private agency: RailAppAgencyService,
    private provider: RailAppProviderService,
    private railAppwebsocket: RailappSocket,
  ) {
    localStorage.setItem('railAPPSesionSeconds', environment.sabreRailAPPSessionSecond.toString());
    this.railAppwebsocket.messageSubject.subscribe({
      next:(message)=>{
        switch (message.data.rail_app_action) {
          case 'session':
            localStorage.setItem('railAPPSesionSeconds', environment.sabreRailAPPSessionSecond.toString())
            break;
          case 'ghost_create_response':
          case 'ghost_update_response':
          case 'ghost_void_response':
          case 'ghost_update_complete_response':
          case 'ghost_complete_response':
            this.xmlCompleteAction$.next(message.data.booking_id);
            break;
        }
      }
    })
  }

  channelValueInit(channel){
    // Socket listner
    this.channelValue = channel;
    this.railAppwebsocket.connect(channel);
  }

  getxmlCompleteAction$(): Observable<any> {
    return this.xmlCompleteAction$.asObservable();
  }

  railAppMessageControl(authenticationService): void {

    setInterval(() => {
      if (parseInt(localStorage.getItem('railAPPSesionSeconds')!) < 1) {
        authenticationService.logout();
      }
      let currentValue = parseInt(localStorage.getItem('railAPPSesionSeconds')!);
      localStorage.setItem('railAPPSesionSeconds', (--currentValue).toString());
    }, 1000);

    const channel = localStorage.getItem('channel');
    if(!channel){
      return
    }
    this.channelValueInit(channel);
  }

  controlRaillAppActions(callback: ()=> void): void {
    this.
      getxmlCompleteAction$()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        callback();
      });
  }

  railAppMessageRetrieveOrCreateGhost(voucher:any,callback: ()=> void): void {

    let bookingsWithoutGhostLocator: string[] = [];
    voucher?.booking?.lines?.forEach((line: any) => {
      if (line?.booking_reference?.ghost_locator) {
        let message = {
          action: 'retrieve_booking',
          locator: line.booking_reference.ghost_locator,
        };
        this.railAppwebsocket.sendMessageViaRailAppSocket(message);
      } else {
        bookingsWithoutGhostLocator.push(line.booking_reference.locator);
      }
    });
    if (bookingsWithoutGhostLocator.length > 0) {
      let message = {
        action: 'ghost_create',
        booking_id: voucher.booking.id,
      };
      this.railAppwebsocket.sendMessageViaRailAppSocket(message);
    }
  }


  railAppMessage(voucher: any, actionApi: string, actionSDK: string, callback?: ()=> void): void {

    if(callback){
      this.controlRaillAppActions(callback);
    }
    voucher.booking?.lines?.forEach((line: any) => {
      let message = {
        action: actionSDK,
        booking_id: voucher.booking.id,
        booking_locator : line.booking_reference.locator
      };
      this.railAppwebsocket.sendMessageViaRailAppSocket(message)
    });
  }


  // Peticiones API
  saveAgency(data): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    // return this.http.get<any>('assets/mocks/rail-app-saveAgency.json');
    return this.http.post<any>(
      `${environment.api_backend_url}/api/admin/rail-app/agency/update`,
      data,
      { headers }
    );
  }

  getAgencyData(): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    // return this.http.get<any>('assets/mocks/rail-app-saveAgency.json');
    return this.http.get<any>(
      `${environment.api_backend_url}/api/admin/rail-app/agency`,
      { headers }
    );
  }

  validateProvider(data, provider): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');
    return this.http.post<any>(
      `${
        environment.api_backend_url
      }/api/admin/rail-app/provider/${provider.toLowerCase()}/update`,
      data,
      { headers }
    );
  }

  getProvidersData(): Observable<any> {
    // return this.http.get<any>('assets/mocks/rail-app-validateProvider.json');
    let headers = new HttpHeaders();
    headers = headers.append('hideError', 'yes');

    // return this.http.get<any>('assets/mocks/rail-app-saveAgency.json');
    return this.http.get<any>(
      `${environment.api_backend_url}/api/admin/rail-app/providers`,
      { headers }
    );
  }

  // Fin peticiones API

  // Funciones para montar formularios, inputs y los estados iniciales de cada form
  getRenfeProviderForm(): any {
    return this.provider.getRenfeProviderForm();
  }

  getRenfeProviderInputs(): any {
    return this.provider.getRenfeProviderInputs();
  }

  getIryoProviderForm(): any {
    return this.provider.getIryoProviderForm();
  }

  getIryoProviderInputs(): any {
    return this.provider.getIryoProviderInputs();
  }

  getInitialStateBasic(): any {
    return this.agency.getInitialStateBasic();
  }

  getInitialStateDetail(): any {
    return this.agency.getInitialStateDetail();
  }

  getInputsStateFormBasic(): any {
    return this.agency.getInputsStateFormBasic();
  }

  getInputsStateFormDetail(countryObj, timezoneObj): any {
    return this.agency.getInputsStateFormDetail(countryObj, timezoneObj);
  }

  getCredentials(provider: string): any {
    return this.provider.getCredentials(provider);
  }

  getAgencyFieldsConfig(): any[] {
    return this.agency.getAgencyFieldsConfig();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
