export let environment = {
  // api_backend_url: 'https://stagingbackend.lleego.es',
  api_backend_url: 'https://middlestaging.lleego.es',
  api_rent_a_car_uri: '/api/v2/rentacar',
  api_url: 'https://testapi.lleego.com', // it value changes user.service
  backend_error_logs: '/api/logs/front',
  carsSearchBaseUrl: '/transport/cars/avail',
  trainsSearchBaseUrl: '/transport/trains/avail',
  dev_api_url: null,
  env: 'staging',
  iframeUrl: 'https://staging.lleego.com',
  phoneWidth: 992,
  private_rent_a_car_uri: '/private/v2/rentacar',
  production: true,
  searchBaseUrl: '/transport/flight',
  searchBaseUrlCC: '/call-center/b2c/transport/flight',
  socketIo: 'wss://offline.lleego.com/ws/',
  urlCO2: 'https://co2.lleego.es/co2calculator',
  logoutIberiaCC: 'https://stagingsso.lleego.es/saml/login',
  veci_domains: [
    'veci.lleego.com',
    'veciapi.lleego.com',
    'veci-app-dev.lleego.com',
    'veci-api-dev.lleego.com',
    'test-api-vc.lleego.com',
    'veci-frontv2.lleego.es',
    'api-vc.lleego.com',
    'veci-cars.lleego.com',
    'veci-frontv2-prod-qa.lleego.es',
  ],
  veciDisneyBudgetButtonURL: 'https://intranet-pre.viajeselcorteingles.es',
  xApiKey: 'cwfEt9hbo07Sbm13p0jgt1kyU6eWy6VY2FNd6oNq',
  carsResultsGoogleKey: 'AIzaSyBGeO3IvBYd6haJoA91kq0aySkNZgn1CMw',
  vistatravel_url: 'https://vt.lleego.es',
  vistatravel_url_credit_balance:
    'https://pre-b2b.avoristravel.com/access/force?callback_url=https://pre-b2b.avoristravel.com/corporate-customer/%23/credit-balance',
  vistatravel_ID_control: [1353, 1398],
  prismaTokenApiKey: 'D1ecVxXoHktEfx4rie0LT87JC4YvJk0W',
  vistatravelAllMigrated: false,
  baseURLS3Document:
    'https://lleego-london.s3.eu-west-2.amazonaws.com/public/documents/',
  quarantine_url:
    'https://zn42xz4hj6vad3npdzvbvcwgkm0zvoyo.lambda-url.eu-west-2.on.aws',
  payway_sdk_javascript: 'https://developers.decidir.com/api/v2',
  sabreRailAPPSessionSecond: 180,
  sending_itinerary_ib: 'https://pree.iberia.es/es/gestion-de-reservas/?language=es&market=es&channel=COM#!/chktrp',
  isIBCCPaymentCash: true,
  clientsControlProviderFIlter:[] as string[],
  internalUsersEnvBaseUrls:[ // Lista de URLs base
    'http://localhost:4200',
    'https://frontv2.lleego.es',
    'https://office.lleego.es'
  ],
  urlLogoutWelcomeFly:'https://b2b.welcomefly.com/commons/logout',
  ibPayData:{
    additionalAddress:" ",
    billingAddress: "1295 Charleston Road",
    country:  "US",
    city: "Mountain View",
    postalCode: "94043",
  },
  ibcc_client_static_data: {
    favicon: 'https://assets.lleego.com/backend/uploads/logo-624abb5867303.png',
    css: {
      bc: '#D7192D',
      bhc: '#FCD100',
      pc: '#D7192D',
      sc: '#faf4d7',
      tc: '#fff6c9',
    },
    logo: 'https://assets.lleego.com/backend/uploads/logo-656f31d8a0481.svg',
    logo_png:
      'https://assets.lleego.com/backend/uploads/logo-656f31d8c9aaf.png',
  },
  isEroskiOrAzulmarinoClient: {
    40: 'eroski',
    79: 'eroski',
    184: 'azul',
  }
};
